<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <v-toolbar flat dense class="grey lighten-5">
        <v-toolbar-title class="py-3 px-0">{{
          $t("route.dataTypes")
        }}</v-toolbar-title>
        <v-spacer />
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
        ></v-text-field>
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn small text color="info" class="mx-2" @click="recache">
          <v-icon>mdi-database-refresh</v-icon>
          <span class="text--info">{{ $t("general.recache") }}</span>
        </v-btn>
      </v-toolbar>

      <v-card-text class="overflow-y-auto px-0 py-0" :style="viewHeight()">
        <v-data-table
          dense
          :headers="headers"
          :items="getTypes"
          item-key="id"
          :search="search"
          single-select
          @click:row="navigateToSelected"
        >
          <template #item.locale="{ item }">
            <v-btn text tile>
              <v-icon small class="mr-2">mdi-cog-outline</v-icon>
              {{ $t(item.locale) }}</v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Types",
  props: {
    route: String,
  },
  data() {
    return {
      search: "",
      deleteDialog: false,
      headers: [{ text: this.$t("general.name"), value: "locale" }],
    };
  },
  computed: {
    ...mapGetters("types", ["getTypeGroups"]),

    getTypes() {
      const list = this.getTypeGroups();
      return list.filter((f) => this.$allowed(`${f.permission}`));
    },
  },
  methods: {
    ...mapActions("types", ["recacheTypes"]),

    navigateToSelected({ route }) {
      this.$router.push({ name: "type", params: { route } });
    },
    recache() {
      this.recacheTypes();
    },
  },
};
</script>
